<template>
  <div style="padding: 10px; background-color: white">
    <LoadingPlaceholder v-if="reportData || componentLoading" />
    <template v-if="reportData">
      <div class="progress mt-3">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: reportData.percentage + '%' }"
          :aria-valuenow="reportData.percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ reportData.percentage + "%" }}
        </div>
      </div>
    </template>
    <div v-if="issueResult && Object.keys(issueResult).length > 0">
      <div
        v-for="(res, index) in issueResult"
        :key="`component-${index}`"
        style="font-size: 17px"
      >
        <div class="row">
          <span
            class="col-12"
            style="font-size: 20px"
          >
            <b>{{ index }}</b>
          </span>
        </div>
        <div class="row mb-2">
          <span
            class="col-12"
            style="font-size: 13px"
          >
            {{ $t("worklogCustomer.billableSupportActivities") }}
            {{ dateTime_date(selectedDateFrom) }} -
            {{ dateTime_date(selectedDateTo) }}
          </span>
        </div>
        <div class="row mb-3">
          <span
            class="col-12"
            style="font-size: 13px"
          >
            {{ $t("worklogCustomer.infoBill") }}:
            <a
              class="alt-primary-color"
              target="_blank"
              href="https://help.alturos.com/support/solutions/articles/29000040473"
            >https://help.alturos.com/support/solutions/articles/29000040473</a>
          </span>
        </div>
        <div class="row">
          <span
            class="col-1"
            style="font-size: 17px"
          >
            <b>JIRA Issue</b>
          </span>
          <span
            class="col-7"
            style="font-size: 17px"
          >
            <b>{{ $t("worklogCustomer.summary") }}</b>
          </span>
          <div class="row col-4">
            <span
              class="col-5"
              style="font-size: 17px"
            >
              <b>{{ $t("worklogCustomer.resolution") }}</b>
            </span>
            <span
              class="col-5"
              style="font-size: 17px"
            >
              <b>{{ $t("worklogCustomer.billingReason") }}</b>
            </span>
            <span
              class="col-2"
              style="font-size: 17px; text-align: end"
            >
              <b>{{ $t("worklogCustomer.effort") }}</b>
            </span>
          </div>
        </div>
        <div
          class="row mb-3"
          style="border-bottom: 1px solid black"
        >
          <span
            class="col-1"
            style="font-size: 14px"
          > FD Reporter </span>
          <span
            class="col-1"
            style="font-size: 14px"
          >
            {{ $t("worklogCustomer.date") }}
          </span>
          <span
            class="col-1"
            style="font-size: 14px"
          >
            {{ $t("worklogCustomer.worker") }}
          </span>
          <span
            class="col-5"
            style="font-size: 14px"
          >
            {{ $t("worklogCustomer.task") }}
          </span>
        </div>
        <div
          v-for="(projects, projectIndex) in res"
          :key="`prj-${index}-${projectIndex}`"
          style="font-size: 15px"
        >
          <div
            v-for="(issue, issueIndex) in projects"
            :key="`itm-${index}-${issueIndex}`"
          >
            <div class="row">
              <span class="col-1">
                <a
                  class="alt-primary-color"
                  target="_blank"
                  :href="jiraUrl(issue.jiraIssueKey)"
                ><b>{{ issue.jiraIssueKey }}</b></a>
              </span>
              <span
                class="col-7"
                v-html="getFdTagSummary(issue.summary, true)"
              />
              <div
                class="row col-4"
                style="padding-right: 0"
              >
                <span class="col-5">
                  <b>{{ issue.resolution }}</b>
                </span>
                <span class="col-5">
                  <b>{{ issue.billingReason }}</b>
                </span>
                <span
                  class="col-2"
                  style="text-align: end"
                >
                  <b>{{
                    issue.customerApprovedEffort === -1
                      ? (
                        issue.worklogs
                          .map((x) => x.timeSpentInSeconds)
                          .reduce((sum, a) => sum + a, 0) / 3600
                      )
                        .toFixed(2)
                        .replace(".", ",")
                      : (issue.customerApprovedEffort / 3600)
                        .toFixed(2)
                        .replace(".", ",")
                  }}</b>
                </span>
              </div>
            </div>
            <template v-if="!showOnlyTasks">
              <div
                v-for="(worklog, worklogIndex) in issue.worklogs"
                :key="`itm-${index}-${issueIndex}-${worklogIndex}`"
                class="row mb-2"
                style="font-size: 13px"
              >
                <span
                  v-if="issue.hostingAndSupportAgreement && worklogIndex == 0"
                  class="col-1"
                >
                  {{ issue.hostingAndSupportAgreement }}
                </span>
                <span
                  v-else
                  class="col-1"
                >
                  {{ worklog.requester }}
                </span>
                <span class="col-1">
                  {{ dateTime_date(worklog.startDate) }}
                </span>
                <span class="col-1">
                  {{ worklog.authorUser.displayName }}
                </span>
                <span
                  class="col-5"
                  style="white-space: pre-wrap"
                  v-html="getFdTagComment(worklog.comment)"
                />
                <span class="col-1" />
                <span class="col-1" />
                <span class="col-1" />
                <span
                  class="col-1"
                  style="text-align: end"
                >
                  {{
                    (worklog.timeSpentInSeconds / 3600)
                      .toFixed(2)
                      .replace(".", ",")
                  }}
                </span>
              </div>
            </template>
          </div>
        </div>
        <div class="row mt-3">
          <span class="col-10" />
          <div
            class="row col-2"
            style="padding-right: 0; font-size: 15px"
          >
            <span class="col-8">
              <b>TOTAL</b>
            </span>
            <span
              class="col-4"
              style="text-align: end"
            >
              <b>{{ getSum(index) }} h</b>
            </span>
          </div>
        </div>
      </div>
    </div>
    <template v-else-if="issueResult && Object.keys(issueResult).length == 0">
      <div>
        {{ $t("noDataAvailable") }}
      </div>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
import { urlParseMixin } from "@/mixins/urlParseMixin";
import { mapGetters } from "vuex";
import { authenticationMixin } from "@/mixins/authenticationMixin";
import FreshdeskWidget from "../../plugins/FreshdeskWidget.js";

export default {
  name: "CustomerWorklogReportPdf",
  mixins: [dateTimeMixin, urlParseMixin, authenticationMixin],
  props: {
    sMasterComponent: {
      type: String,
      required: false,
      default: null,
    },
    sComponent: {
      type: String,
      required: false,
      default: null,
    },
    sStartDate: {
      type: String,
      required: false,
      default: null,
    },
    sEndDate: {
      type: String,
      required: false,
      default: null,
    },
    onlyTasks: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
      componentLoading: false,
      selectedDateFrom: this.moment().subtract(1, "months").startOf("month"),
      selectedDateTo: this.moment().subtract(1, "months").endOf("month"),
      issueResult: null,
      progress: 0,
      components: null,
      component: "All",
      masterComponent: null,
      multipleComponents: [],
      reportInterval: null,
      reportData: null,
    };
  },
  computed: {
    ...mapGetters(["store_getJiraUrl"]),
    showOnlyTasks () {
      return this.onlyTasks === true || this.onlyTasks === "true";
    },
  },
  beforeDestroy () {
    FreshdeskWidget.show();
    this.cancelInterval();
  },
  created () {
    FreshdeskWidget.hide();
    this.selectedDateFrom = this.sStartDate;
    this.selectedDateTo = this.sEndDate;

    if (this.sMasterComponent && this.sMasterComponent != "null") {
      this.masterComponent = this.sMasterComponent;
      this.multipleComponents = this.sComponent.split(",");
      this.getChunksOfReports();
    } else {
      this.component = this.sComponent;
      if (this.component == "All") {
        this.getComponents();
      } else {
        this.components = [];
        this.getChunksOfReports();
      }
    }
  },
  mounted () {
    var header = document.getElementById("m_header");
    header.remove();
    var menu = document.getElementsByClassName("mainMenu");
    for (var m of menu) {
      m.remove();
    }
    var main = document.getElementsByClassName("m-body");
    for (var d of main) {
      d.classList.add("npd");
      d.setAttribute("style", "padding-top: 0px !important;");
    }
  },
  methods: {
    getComponents () {
      this.componentLoading = true;
      this.axios
        .get(`/Issue/GetWorklogComponents`)
        .then((response) => {
          if (response.status == 200) {
            this.components = response.data;
          }
        })
        .finally(() => {
          this.componentLoading = false;
          this.getChunksOfReports();
        });
    },
    getFdTagSummary (text, bold) {
      if(text.includes('[') && text.includes(']')) {
        let sIndex = text.indexOf('[');
        let eIndex = text.indexOf(']');
        let id = text.substring(sIndex, eIndex).replace('[', '').replace(']', '');
        if(!isNaN(parseInt(id)) && !id.includes(' ')) {
          let url = `https://alturos.freshdesk.com/a/tickets/${id}`;
          let anchor = `<a class="alt-primary-color" target="_blank" href="${url}">${id}</a>`;
          return bold
            ? `<b>${text.replace(id, anchor)}</b>`
            : `${text.replace(id, anchor)}`;
        }
        return bold ? `<b>${text}</b>` : text;
      }
      return bold ? `<b>${text}</b>` : text;
    },
    getFdTagComment (text) {
      if (text.includes("FD#")) {
        var tmpText = text.replace(" ", "");
        var startingIndices = [];
        var indexOccurence = tmpText.indexOf("FD#", 0);

        while (indexOccurence >= 0) {
          startingIndices.push(indexOccurence + 3);
          indexOccurence = tmpText.indexOf("FD#", indexOccurence + 1);
        }

        for (var indx of startingIndices) {
          var tmpId = "";
          for (let i = indx; i < tmpText.length; i++) {
            if (!isNaN(parseInt(tmpText[i]))) {
              tmpId += tmpText[i];
            } else {
              break;
            }
          }
          if(tmpId != '') {
            let url = `https://alturos.freshdesk.com/a/tickets/${tmpId}`;
            let anchor = `<a class="alt-primary-color" target="_blank" href="${url}">${tmpId}</a>`;
            text = text.replace(tmpId, anchor);
          }
        }
        return text;
      }

      return `${text}`;
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(
        this.store_getJiraUrl,
        `browse/${endpoint}`
      );
    },
    getSum (index) {
      let count = 0;
      let values = this.issueResult;
      for (let proj in values[index]) {
        let wl = values[index][proj]
          .filter((x) => x.customerApprovedEffort === -1)
          .map((x) => x.worklogs);
        for (var item of wl) {
          let mp = item
            .map((x) => x.timeSpentInSeconds)
            .reduce((sum, a) => sum + a, 0);
          count += mp;
        }
        count += values[index][proj]
          .filter((x) => x.customerApprovedEffort != -1)
          .map((x) => x.customerApprovedEffort)
          .reduce((sum, a) => sum + a, 0);
      }

      return (count / 3600).toFixed(2).replace(".", ",");
    },
    createInterval () {
      this.reportData = { percentage: 0 };
      this.reportInterval = window.setInterval(() => {
        this.getReportProgress();
      }, 2000);
    },
    cancelInterval () {
      this.reportData = null;
      window.clearInterval(this.reportInterval);
      this.reportInterval = null;
      this.reportData = null;
    },
    getReportProgress () {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.axios
        .get(`/Issue/GetWorklogReportData?user=${email}`)
        .then((response) => {
          if (response.status == 200 && response.data) {
            this.reportData = response.data;
            if (this.reportData.value) {
              if (this.reportData.value) {
                for (var item in this.reportData.value) {
                  var grouped = this.reportData.value[item].reduce(function (
                    r,
                    a
                  ) {
                    r[a.project] = r[a.project] || [];
                    r[a.project].push(a);
                    return r;
                  },
                  Object.create(null));
                  this.issueResult[item] = grouped;
                }
              }
              this.cancelInterval();
            } else if (this.reportData.error) {
              this.cancelInterval();
            }
          } else {
            this.reportData = null;
          }
        });
    },
    async getChunksOfReports () {
      if (!(this.sMasterComponent && this.sMasterComponent != "null")) {
        let comps =
          this.component == "All"
            ? this.components.filter(
                (x) =>
                  x != "All" &&
                  x != "Support Non-billable" &&
                  x != "Support Hour Package"
              )
            : [this.component];
        await this.generateReport(comps);
        this.loading = false;
      } else {
        await this.generateReport(
          this.multipleComponents,
          this.masterComponent
        );
        this.loading = false;
      }
    },
    async generateReport (itm, masterComponent) {
      var user = this.authenticationGetUser();
      var email = user.details.profile.email;
      this.issueResult = {};
      var req = {
        components: itm,
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
        masterComponent: masterComponent,
        user: email,
      };

      await this.axios.post(`/Issue/GetCustomerWorklogReport`, req).then(() => {
        this.createInterval();
      });
    },
  },
};
</script>

<style>
/* @media print {
  @page {size: A4 landscape}
    body {-moz-transform:rotate(-90deg) scale(.58,.58)}
    .row {
      page-break-inside:avoid; page-break-after:auto;
    }
    .hrs {
      white-space: nowrap;
    }
} */

@media print {
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  @page {
    size: A4 landscape;
    max-height: 100%;
    max-width: 100%;
  }
  .row {
    page-break-inside: avoid;
    page-break-after: auto;
  }
}

.npd {
  padding: 0px !important;
  padding-top: 0px !important;
}
</style>
